import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Input: ComponentMultiStyleConfig = {
  parts: ['field', 'addon', 'leftAddon', 'element'],
  baseStyle: {
    field: {
      fontFamily: 'DM Sans',
      borderWidth: '1px',
    },
    element: {
      borderRightWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      bgColor: 'white',
      borderRightRadius: '12px',
      borderColor: 'brand.darkBlue100',

      px: '12px',
    },
  },
  variants: {
    outline: {
      field: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRadius: '12px',
        borderColor: 'brand.darkBlue100',
        bgColor: 'white',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _hover: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          // bgColor: 'state.error',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
      addon: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRight: 'none',
        outlineRight: 'none',
        fontFamily: 'DM Sans SemiBold',
        borderRadius: '12px',
        borderColor: 'brand.darkBlue100',
        bgColor: 'white',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          bgColor: 'white',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          bgColor: 'state.errorHover',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
    },
    flushed: {
      field: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRadius: '12px',
        borderColor: 'brand.darkBlue100',
        bgColor: 'white',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _hover: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          // bgColor: 'state.error',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
      addon: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRight: 'none',
        outlineRight: 'none',
        fontFamily: 'DM Sans SemiBold',
        borderRadius: '12px',
        borderColor: 'brand.darkBlue100',
        bgColor: 'white',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          bgColor: 'white',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          bgColor: 'state.errorHover',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
    },
    offerItem: {
      field: {
        p: '8px',
        borderRadius: '4px',
        border: 'none',
        bgColor: '#EEF3FE',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          color: '#6872A5',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          border: '1px',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue20',
        },
        _hover: {
          boxShadow: 'none',
          border: '1px',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue20',
        },
        _invalid: {
          bgColor: 'state.error',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
    },

    categorySearch: {
      field: {
        h: '68px',
        px: '16px',
        py: '24px',
        borderRadius: '12px',
        borderColor: '#CCCCCC',
        bgColor: 'white',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _hover: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          // bgColor: 'state.error',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
      element: {
        borderRightWidth: '1px',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        bgColor: 'white',
        borderRightRadius: '12px',
        borderColor: '#CCCCCC',

        px: '12px',
      },
      addon: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRight: 'none',
        outlineRight: 'none',
        fontFamily: 'DM Sans SemiBold',
        borderRadius: '12px',
        borderColor: 'red',
        bgColor: 'red',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          bgColor: 'white',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          bgColor: 'state.errorHover',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
    },
  },
}
