export enum FontFamily {
  DMSansThin = 'DM Sans Thin',
  DMSansLight = 'DM Sans Light',
  DMSans = 'DM Sans',
  DMSansMedium = 'DM Sans Medium',
  DMSansSemibold = 'DM Sans Semibold',
  DMSansBold = 'DM Sans Bold',
  DMMono = 'DM Mono',
  DMMonoMedium = 'DM Mono Medium',
  MontserratExtraLight = 'Montserrat Extra Light',
  MontserratLight = 'Montserrat Light',
  Montserrat = 'Montserrat',
  MontserratMedium = 'Montserrat Medium',
  MontserratSemibold = 'Montserrat Semibold',
}

export const FontUrl: Record<FontFamily, string> = {
  [FontFamily.DMSans]: '/fonts/DM-Sans/DMSans-Regular.ttf',
  [FontFamily.DMSansThin]: '/fonts/DM-Sans/DMSans-Thin.ttf',
  [FontFamily.DMSansLight]: '/fonts/DM-Sans/DMSans-Light.ttf',
  [FontFamily.DMSansMedium]: '/fonts/DM-Sans/DMSans-Medium.ttf',
  [FontFamily.DMSansSemibold]: '/fonts/DM-Sans/DMSans-SemiBold.ttf',
  [FontFamily.DMSansBold]: '/fonts/DM-Sans/DMSans-Bold.ttf',
  [FontFamily.DMMono]: '/fonts/DM-Mono/DMMono-Regular.ttf',
  [FontFamily.DMMonoMedium]: '/fonts/DM-Mono/DMMono-Medium.ttf',
  [FontFamily.Montserrat]: '/fonts/Montserrat/Montserrat-Regular.ttf',
  [FontFamily.MontserratExtraLight]: '/fonts/Montserrat/Montserrat-ExtraLight.ttf',
  [FontFamily.MontserratLight]: '/fonts/Montserrat/Montserrat-Light.ttf',
  [FontFamily.MontserratMedium]: '/fonts/Montserrat/Montserrat-Medium.ttf',
  [FontFamily.MontserratSemibold]: '/fonts/Montserrat/Montserrat-SemiBold.ttf',
}
