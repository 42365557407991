import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { transparentize } from 'polished'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

export const Card = defineMultiStyleConfig({
  baseStyle: {
    ...definePartsStyle({
      container: {
        backgroundColor: 'white',
        borderRadius: '12px',
      },
      // header: {
      //   paddingBottom: '2px',
      // },
      // body: {
      //   paddingTop: '2px',
      // },
      // footer: {
      //   paddingTop: '2px',
      // },
    }),
  },
  variants: {
    layout: {
      container: {
        boxShadow: `0px 24px 64px 0px ${transparentize(0.95, 'black')}`,
        borderRadius: 'md',
      },
    },
  },
})
