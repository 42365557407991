import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Checkbox: ComponentMultiStyleConfig = {
  parts: ['control', 'container', 'label', 'icon'],
  baseStyle: {
    icon: {
      height: '16px',
      borderRadius: 'none',
      color: 'white',
      border: 'none',

      _selected: {
        boxShadow: 'none',
        bgColor: 'brand.blue100',
        borderColor: 'brand.blue100',
      },
      _selection: {
        boxShadow: 'none',
        bgColor: 'brand.primary',
        borderColor: 'brand.primary',
      },
      _focusVisible: {
        boxShadow: 'none',
        bgColor: 'brand.primary',
        borderColor: 'brand.primary',
        _hover: {
          boxShadow: 'none',
        },
      },
    },
    control: {
      borderColor: 'brand.blue100',
      borderWidth: '2px',
      borderRadius: '4px',
      height: '20px',
      width: '20px',

      _focusVisible: {
        boxShadow: 'none',
        bgColor: 'brand.blue100',
        borderColor: 'brand.blue100',
        _hover: {
          boxShadow: 'none',
        },
      },
      _selected: {
        boxShadow: 'none',
        bgColor: 'brand.blue40',
        borderColor: 'brand.blue40',
      },
      _selection: {
        boxShadow: 'none',
        bgColor: 'brand.blue40',
        borderColor: 'brand.primary',
      },
    },
    label: {
      fontFamily: 'Dm Sans',
      color: 'brand.black',
      borderRadius: 'none',
      fontSize: '14px',
      lineHeight: '20px',
      _focus: {
        boxShadow: 'none',
        bgColor: 'brand.blue40',
        borderColor: 'brand.blue40',
      },
      _selected: {
        boxShadow: 'none',
        bgColor: 'brand.blue40',
        borderColor: 'brand.blue40',
      },
      _selection: {
        boxShadow: 'none',
        bgColor: 'brand.primary',
        borderColor: 'brand.primary',
      },
    },
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: 'fit-content',
    },
  },
  sizes: {
    md: {
      control: {
        w: '4',
        h: '4',
      },
      label: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
  variants: {
    primary: {
      icon: {
        color: 'white',
      },
      control: {
        borderColor: 'brand.blue100',
        _checked: {
          bgColor: 'brand.blue100',
          borderColor: 'brand.blue100',
          _hover: {
            bgColor: 'brand.blue60',
            borderColor: 'brand.blue60',
          },
          _focusVisible: {
            boxShadow: 'none',
            bgColor: 'brand.blue40',
            _hover: {
              boxShadow: 'none',
            },
          },
        },
      },

      _focusVisible: {
        boxShadow: 'none',
        bgColor: 'brand.blue40',
        _hover: {
          boxShadow: 'none',
        },
      },
    },
  },
}
