import { InMemoryCache } from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'

export const createInMemoryCache = () =>
  new InMemoryCache({
    possibleTypes: {
      X: ['Y'],
    },
    typePolicies: {
      // Notification: {
      //   keyFields: ['id', 'createdAt'],
      // },
      // Demand: {
      //   keyFields: ['id', 'name'],
      // },
      Address: {
        keyFields: ['id'],
      },
      Contractor: {
        keyFields: ['id', 'userId'],
        fields: {
          company: {
            merge(existing, incoming) {
              // TODO https://github.com/apollographql/apollo-client/issues/5762
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return { ...existing, ...incoming }
            },
          },
        },
      },
      Company: {
        keyFields: ['id', 'userId'],
      },
      Query: {
        fields: {
          demands: offsetLimitPagination(),
        },
      },
    },
  })
