import { isNil, omitBy } from 'lodash'
import { stringify } from 'querystring'

export const getValueOrThrow = <T>(value?: T | null, label?: string | null): T => {
  if (isNil(value)) {
    throw new Error(`Expecting non-nil value${label ? ` for property ${label}` : ''}`)
  }

  return value
}

export const withSearchParams = (searchParams = {}): string => {
  const paramsString = stringify(omitBy(searchParams, isNil))
  if (!paramsString) {
    return ''
  }
  return `?${paramsString}`
}
