import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    _focus: {
      boxShadow: 'none',
    },
    fontFamily: 'DM Sans Semibold',
    lineHeight: '20px',
    borderRadius: '12px',
  },
  sizes: {
    sm: {
      w: 'auto',
      h: 'auto',
      px: '12px',
      py: '6px',
      fontSize: '14px',
    },
    md: {
      w: 'auto',
      h: 'auto',
      px: '16px',
      py: '8px',
      fontFamily: 'DM Sans Semibold',
      fontSize: '16px',
    },
    lg: {
      w: 'auto',
      h: 'auto',
      px: '24px',
      py: '16px',
      fontFamily: 'DM Sans Semibold',
      fontSize: '16px',
    },
  },
  variants: {
    primary: {
      bgColor: 'brand.blue100',
      color: 'white',
      borderWidth: '1px',
      borderColor: 'brand.blue100',
      _active: {
        borderWidth: '1px',
        borderColor: 'brand.blue100',
        backgroundColor: '#BDD4FF',
        color: 'brand.black',
      },
      _hover: {
        bgGradient: 'none',
        bgColor: 'brand.blue80',
        color: 'white',
        _disabled: {
          bgGradient: 'none',
          color: 'brand.darkBlue40',
          bgColor: 'brand.ghostWhite',
          opacity: '1',
        },
      },
      _disabled: {
        bgGradient: 'none',
        color: 'brand.darkBlue40',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    secondary: {
      bgColor: 'brand.blue20',
      color: 'brand.darkBlue100',
      _active: {
        bgColor: 'brand.darkBlue100',
        color: 'brand.blue10',
      },
      _hover: {
        bgGradient: 'none',
        bgColor: 'brand.blue60',
        color: 'white',
        _disabled: {
          bgGradient: 'none',
          color: 'brand.darkBlue40',
          bgColor: 'brand.ghostWhite',
          opacity: '1',
        },
      },
      _disabled: {
        bgGradient: 'none',
        color: 'brand.darkBlue40',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    link: {
      fontFamily: 'DM Sans Medium',
      fontSize: '16px',
      lineHeight: '150%',
      bgColor: 'none',
      p: '0px',
      color: 'brand.blue100',
      textDecoration: 'underline',
      _active: {
        bgColor: 'none',
        color: 'brand.blue10',
      },
      _hover: {
        color: 'brand.darkBlue80',
        _disabled: {
          color: 'brand.darkBlue40',
          opacity: '1',
          cursor: 'not-allowed',
        },
      },
      _disabled: {
        color: 'brand.darkBlue40',
        cursor: 'not-allowed',
        opacity: '1',
      },
    },
    gradient: {
      border: '1.5px solid',
      borderColor: 'transparent',
      background: `linear-gradient(white, white) padding-box, 
        linear-gradient(92deg, #2B5CBC 3.36%, #1E8BEF 84.15%) border-box`,
      '> *': {
        background: `linear-gradient(92deg, #2B5CBC 3.36%, #1E8BEF 84.15%)`,
        backgroundClip: 'text',
        textFillColor: 'transparent',
      },
      _hover: {
        background: `white padding-box, 
          linear-gradient(92deg, #1E8BEF 3.36%, #2B5CBC 84.15%) border-box`,
        '> *': {
          background: `linear-gradient(92deg, #2B5CBC 3.36%, #1E8BEF 84.15%)`,
          backgroundClip: 'text',
        },
      },
    },
    outline: {
      bgColor: 'white',
      color: 'brand.blue100',
      borderWidth: '1.5px',
      borderColor: 'brand.blue100',
      _active: {
        borderColor: 'brand.darkBlue100',
        bgColor: 'brand.darkBlue100',
        color: 'brand.blue10',
      },
      _hover: {
        borderColor: 'brand.blue60',
        bgColor: 'brand.blue60',
        color: 'white',
        _disabled: {
          bgGradient: 'none',
          color: 'brand.darkBlue40',
          bgColor: 'brand.ghostWhite',
          opacity: '1',
        },
      },
      _disabled: {
        color: 'brand.darkBlue40',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    sideMenu: {
      justifyContent: 'left',
      bgColor: 'transparent',
      color: 'brand.black',
      lineHeight: '24px',
      py: '12px',
      px: '16px',
      height: '48px',
      _active: {
        color: 'brand.blue100',
      },
      _hover: {
        color: 'brand.blue100',
        _disabled: {
          bgGradient: 'none',
          color: 'brand.darkBlue40',
          opacity: '1',
        },
      },
      _disabled: {
        color: 'brand.darkBlue40',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
    ghost: {
      _hover: {
        bgColor: 'transparent',
      },
      _active: {
        bgColor: 'transparent',
      },
    },

    pagination: {
      bgColor: 'brand.blue10',
      color: 'brand.black',
      height: '40px',
      _hover: {
        bgColor: 'brand.darkBlue10',
        color: 'brand.blue100',
      },
    },
    paginationActive: {
      bgColor: 'brand.blue100',
      color: 'brand.white',
      width: '20px',
      height: '40px',
      fontSize: '14px',
      _focus: {
        boxShadow: 'none',
      },
    },
    dashboardStatusFilter: {
      bgColor: '#EDF2F7',
      color: 'brand.darkBlue100',
      fontSize: '12px',
      fontFamily: 'DM Sans',
      width: '100px',
      height: '40px',
      borderRadius: '4px',
      _active: {
        borderWidth: '1px',
        borderColor: 'brand.blue100',
        backgroundColor: '#BDD4FF',
      },
      _hover: {
        color: 'brand.blue100',
        bgColor: '#BDD4FF',
        _disabled: {
          bgGradient: 'none',
          color: 'brand.darkBlue40',
          bgColor: 'brand.ghostWhite',
          opacity: '1',
        },
      },
      _disabled: {
        bgGradient: 'none',
        color: 'brand.darkBlue40',
        bgColor: 'brand.ghostWhite',
        opacity: '1',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'lg',
  },
}
