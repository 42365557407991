import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const Textarea: ComponentSingleStyleConfig = {
  baseStyle: {
    field: {
      fontFamily: 'DM Sans',
      borderWidth: '1px',
    },
    fontFamily: 'DM Sans',
    borderWidth: '1px',
    px: '16px',
    py: '16px',
    borderRadius: '12px',
    borderColor: 'brand.darkBlue100',
    bgColor: 'white',
    background: 'white',
    color: 'brand.darkBlue100',
    lineHeight: '18px',
    fontSize: '16px',
    _placeholder: {
      color: 'brand.darkBlue40',
      lineHeight: '18px',
      fontSize: '16px',
      background: 'white',
      backgroundColor: 'white',
    },
    _focus: {
      boxShadow: 'none',
      borderColor: 'brand.blue100',
      bgColor: 'brand.blue10',
      background: 'white',
      backgroundColor: 'white',
    },
    _hover: {
      boxShadow: 'none',
      borderColor: 'brand.blue100',
      bgColor: 'brand.blue10',
    },
    _invalid: {
      bgColor: 'state.error',
      borderWidth: '1px',
      boxShadow: 'none',
    },
  },
  variants: {
    outline: {
      field: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRadius: '12px',
        borderColor: 'brand.darkBlue100',
        bgColor: 'white',
        color: 'red',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
          textTransform: 'lowerCase',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _hover: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          bgColor: 'state.error',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
      addon: {
        h: '50px',
        px: '16px',
        py: '16px',
        borderRight: 'none',
        outlineRight: 'none',
        fontFamily: 'DM Sans SemiBold',
        borderRadius: '12px',
        borderColor: 'brand.darkBlue100',
        bgColor: 'white',
        color: 'brand.darkBlue100',
        lineHeight: '18px',
        fontSize: '16px',
        _placeholder: {
          textTransform: 'none',
          bgColor: 'white',
          color: 'brand.darkBlue40',
          lineHeight: '18px',
          fontSize: '16px',
        },
        _focus: {
          boxShadow: 'none',
          borderColor: 'brand.blue100',
          bgColor: 'brand.blue10',
        },
        _invalid: {
          bgColor: 'state.errorHover',
          borderWidth: '1px',
          boxShadow: 'none',
        },
      },
    },
  },
}
