import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Radio: ComponentMultiStyleConfig = {
  parts: ['control', 'label'],
  baseStyle: {
    control: {
      borderColor: 'brand.blue100',
      borderWidth: '2px',
      cursor: 'pointer',
      _checked: {
        bgColor: 'brand.blue100',
        borderColor: 'brand.blue100',
        color: 'brand.white',
        _hover: {
          bgColor: 'brand.blue100',
          borderColor: 'brand.blue100',
          color: 'brand.white',
        },
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    label: {
      pl: '2',
      lineHeight: 'shorter',
      fontSize: '14px',
      fontFamily: 'Dm Sans Medium',
    },
  },
  sizes: {
    md: {
      control: {
        w: '18px',
        h: '18px',
      },
      label: {
        fontSize: '14px',
      },
    },
    lg: {
      control: {
        w: '30px',
        h: '30px',
      },
      label: {
        fontSize: '14px',
      },
    },
  },
}
