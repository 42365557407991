import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { useApollo } from 'hooks/useApollo'
import type { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { BaseAppProps, NextPageWithLayout } from 'types/next'
import { theme } from 'theme'
import { Fonts } from 'theme/Fonts'
import { DefaultSeoConfig } from 'constants/seo'
import UserProvider from 'providers/UserProvider'

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPageWithLayout<BaseAppProps>
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <DefaultSeo {...DefaultSeoConfig} />
      <ChakraProvider theme={theme}>
        <Fonts />
        <ApolloProvider client={apolloClient}>
          <UserProvider>{getLayout(<Component {...pageProps} />)}</UserProvider>
        </ApolloProvider>
      </ChakraProvider>
    </>
  )
}

export default App
