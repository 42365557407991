import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const FormLabel: ComponentSingleStyleConfig = {
  variants: {
    outline: {
      fontFamily: 'DM Sans Medium',
      fontSize: '14px',
      color: 'brand.darkBlue100',
    },
    switch: {
      fontFamily: 'DM Sans Medium',
      fontSize: '14px',
      color: 'brand.darkBlue100',
    },
  },
}
