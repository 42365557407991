import { DefaultSeoProps } from 'next-seo'

export const DefaultSeoConfig: DefaultSeoProps = {
  title: 'Zadaj dopyt',
  titleTemplate: '%s | Zadaj dopyt',
  defaultTitle: 'Zadaj dopyt | Dopyty a Priame ponuky pre každý projekt',
  description:
    'Nezávislé cenové ponuky na kvalifikované stavebné práce a všetky súvisiace služby. Pre stavby, renovácie, opravy, údržbu, zariaďovanie nehnuteľností... to všetko na zadajopyt.sk!',
  openGraph: {
    type: 'website',
    locale: 'sk_SK',
    url: 'https://zadajdopyt.sk/',
    siteName: 'Zadaj dopyt | Dopyty a Priame ponuky pre každý projekt',
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
}
