// import { transparentize } from 'polished'

export const DeviceBreakpoints = {
  xs: '320px',
  sm: '480px',
  md: '700px', // Tablet
  lg: '1025px', // Desktop
  '2xl': '1534px',
}

export interface ThemeColors {
  brand: {
    blue100: string
    blue80: string
    blue60: string
    blue40: string
    blue20: string
    blue10: string
    darkBlue100: string
    darkBlue80: string
    darkBlue60: string
    darkBlue40: string
    darkBlue20: string
    darkBlue10: string
    deepPurple: string
    ebony: string
    darkEbony: string
    ebony50: string
    wildBlueYonder: string
    fogGray: string
    ghostWhite: string
    darkWildBlueYonder: string
    gainsboro: string
    brightGray: string
    selago: string
    black: string
    white: string
  }
  state: {
    success: string
    warning: string
    error: string
    info: string
    hover: string
    hoverLight: string
    errorHover: string
    errorLight: string
  }
}

export const Gradients = {
  blue: 'linear-gradient(92deg, #2B5CBC 3.36%, #1E8BEF 84.15%)',
  lightBlue: 'linear-gradient(90deg, #EAEFFF -36.12%, #FFF 100%)',
  lightBlueReverse: 'linear-gradient(240deg, #EAEFFF -36.12%, #FFF 100%)',
} as const

export const Colors: ThemeColors = {
  brand: {
    blue100: '#007FFF',
    blue80: '#4CA2F3',
    blue60: '#79B9F6',
    blue40: '#A5D1F9',
    blue20: '#D2E8FC',
    blue10: '#E9F3FD',
    darkBlue100: '#2B2B69',
    darkBlue80: '#565687',
    darkBlue60: '#8080A5',
    darkBlue40: '#AAAAC3',
    darkBlue20: '#D5D5E1',
    darkBlue10: '#EAEAF0',
    deepPurple: '#0F1526',
    ebony: '#243340',
    darkEbony: '#222831',
    ebony50: '#9FA1A8',
    wildBlueYonder: '#A4ACCA',
    darkWildBlueYonder: '#5C5F88',
    gainsboro: '#D8DBE7',
    brightGray: '#3F4452',
    fogGray: '#F3F6F9',
    ghostWhite: '#F7F8FB',
    selago: '#EFEFFC',
    black: '#252525',
    white: '#ffffff',
  },
  state: {
    success: '#76CA66',
    warning: '#FBC756',
    error: '#BA0000',
    info: '#A0C3FF',
    hover: '#798BFF',
    hoverLight: '#EAEBFF',
    errorHover: '#F4D5D5',
    errorLight: '#FEFAFE',
  },
}

export const TEXT_COLOR = Colors.brand.darkBlue100
export const BASE_FONT_SIZE_PX = '14px'
