import { Global } from '@emotion/react'
import { FontFamily, FontUrl } from 'constants/common/fonts'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: ${FontFamily.DMSansThin};
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url(${FontUrl['DM Sans Thin']}) format('TrueType'), url(${FontUrl['DM Sans Thin']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMSansLight};
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${FontUrl['DM Sans Light']}) format('TrueType'), url(${FontUrl['DM Sans Light']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMSans};
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${FontUrl['DM Sans']}) format('TrueType'), url(${FontUrl['DM Sans']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMSansMedium};
        font-style: normal;
        font-weight: 500 ;
        font-display: swap;
        src: url(${FontUrl['DM Sans Medium']}) format('TrueType'), url(${FontUrl['DM Sans Medium']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMSansSemibold};
        font-style: normal;
        font-weight: 600 ;
        font-display: swap;
        src: url(${FontUrl['DM Sans Semibold']}) format('TrueType'), url(${FontUrl['DM Sans Semibold']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMSansBold};
        font-style: normal;
        font-weight: 700 ;
        font-display: swap;
        src: url(${FontUrl['DM Sans Bold']}) format('TrueType'), url(${FontUrl['DM Sans Bold']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMMono};
        font-style: normal;
        font-weight: 400 ;
        font-display: swap;
        src: url(${FontUrl['DM Mono']}) format('TrueType'), url(${FontUrl['DM Mono']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.DMMonoMedium};
        font-style: normal;
        font-weight: 500 ;
        font-display: swap;
        src: url(${FontUrl['DM Mono Medium']}) format('TrueType'), url(${FontUrl['DM Mono Medium']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.MontserratExtraLight};
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url(${FontUrl['Montserrat Extra Light']}) format('TrueType'), url(${FontUrl['Montserrat Extra Light']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.MontserratLight};
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${FontUrl['Montserrat Light']}) format('TrueType'), url(${FontUrl['Montserrat Light']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.Montserrat};
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${FontUrl.Montserrat}) format('TrueType'), url(${FontUrl.Montserrat}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.MontserratMedium};
        font-style: normal;
        font-weight: 500 ;
        font-display: swap;
        src: url(${FontUrl['Montserrat Medium']}) format('TrueType'), url(${FontUrl['Montserrat Medium']}) format('ttf');
      }
      @font-face {
        font-family: ${FontFamily.MontserratSemibold};
        font-style: normal;
        font-weight: 600 ;
        font-display: swap;
        src: url(${FontUrl['Montserrat Semibold']}) format('TrueType'), url(${FontUrl['Montserrat Semibold']}) format('ttf');
      }
      `}
  />
)
