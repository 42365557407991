import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import {
  AuthError,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
} from 'firebase/auth'
import {
  AuthProvider,
  SignUpInput,
  UserMe,
  UserMeDocument,
  UserMeQuery,
  UserType,
  useSignUpMutation,
} from 'generated/generated-graphql'
import { once } from 'lodash'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import { FirebaseApp } from 'utils/firebase'
import { useErrorToast } from 'utils/toast'

interface EmailAndPasswordInput {
  email: string
  password: string
  shouldRedirect?: boolean
}

interface ConfirmPasswordResetInput {
  resetCode: string
  password: string
}

interface UserContextType {
  isLoading: boolean
  isFirebaseInitializing: boolean
  userData: UserMe | null
  signOut: (shouldRedirect?: boolean) => Promise<void>
  signInWithEmailAndPassword: ({
    email,
    password,
    shouldRedirect,
  }: {
    email: string
    password: string
    shouldRedirect?: boolean
  }) => Promise<boolean>
  signInWithFirebaseToken: (token: string) => Promise<void>
  createUserWithEmailAndPassword: ({ email, password, userType }: SignUpInput) => Promise<void>
  createUserWithGoogleOAuth: (input: SignUpInput & { shouldRedirect?: boolean }) => Promise<boolean>
  refetchAndSetUserData: () => Promise<void>
  sendResetPasswordEmail: ({ email }: { email: string }) => Promise<boolean>
  confirmPasswordReset: ({ resetCode, password }: ConfirmPasswordResetInput) => Promise<boolean>
  changePassword: (newPassword: string) => Promise<void> | null
  isCustomer: boolean
  isContractor: boolean
  hasMissingProfileInformations: boolean
  hasMissingOrExpiredSubscription: boolean
  isValidContractor: boolean
}

interface Props {
  children: ReactNode
}

const GENERAL_PERSISTS_ERROR =
  'Something went wrong. Please, try again later. If the problem persists, contact us.'

const FirebaseAuth = getAuth(FirebaseApp)

if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST) {
  once(() => {
    connectAuthEmulator(FirebaseAuth, process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST!)
  })()
}

const UserContext = createContext({} as UserContextType)

export const useAuth = (): UserContextType => useContext(UserContext)

const UserProvider = ({ children }: Props) => {
  const [userData, setUserData] = useState<UserMe | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOAuthSignUp, setIsOAuthSignUp] = useState(false)
  const [isFirebaseInitializing, setIsFirebaseInitializing] = useState(true)
  const errorToast = useErrorToast()
  const router = useRouter()
  const [signUp] = useSignUpMutation()
  const apolloClient = useApolloClient()

  const isCustomer = userData?.userType === UserType.Customer
  const isContractor = userData?.userType === UserType.Contractor
  const hasMissingProfileInformations = userData?.hasMissingInfo ?? false
  const isValidContractor = isContractor && !userData?.hasMissingOrExpiredSubscription
  const hasMissingOrExpiredSubscription = userData?.hasMissingOrExpiredSubscription ?? false

  const createUserWithEmailAndPassword = useCallback(
    async ({
      email,
      password,
      userType,
      firstName,
      lastName,
      phoneNumber,
      subscriptionTier,
      authProvider,
    }: SignUpInput) => {
      try {
        setIsLoading(true)

        if (authProvider !== AuthProvider.Password || !password) {
          throw new Error('Wrong sign up method')
        }

        const response = await signUp({
          variables: {
            input: {
              authProvider,
              email,
              password,
              userType,
              firstName,
              lastName,
              phoneNumber,
              subscriptionTier,
            },
          },
        })
        if (!response.data?.signUp) {
          return
        }
        await firebaseSignInWithEmailAndPassword(FirebaseAuth, email, password)
        await router.push(Route.App.DemandList())
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        setIsLoading(false)
        void router.push(Route.SignIn())
      }
    },
    [router, signUp],
  )

  const signInWithFirebaseToken = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true)
        await firebaseSignInWithCustomToken(FirebaseAuth, token)
      } catch (error) {
        const err = error as AuthError
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorToast({
              description: 'Wrong email address or password',
            })
            break
          case 'auth/network-request-failed':
            errorToast({
              description: 'Something went wrong with your internet connection. Please try again.',
            })
            break
          default:
            Sentry.captureException(err)
            errorToast({
              description: GENERAL_PERSISTS_ERROR,
            })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [errorToast],
  )

  const createUserWithGoogleOAuth = useCallback(
    async ({
      userType,
      firstName,
      lastName,
      phoneNumber,
      subscriptionTier,
      authProvider,
      shouldRedirect = true,
      setLoading,
    }: SignUpInput & { shouldRedirect?: boolean; setLoading?: (value: boolean) => void }) => {
      try {
        if (authProvider !== AuthProvider.Google) {
          throw new Error('Wrong sign up method')
        }
        setIsLoading(true)
        setIsOAuthSignUp(true)
        const auth = getAuth()
        const provider = new GoogleAuthProvider()
        provider.setCustomParameters({
          tosUrl: 'https://zadajdopyt.sk/support/podmienky-pouzivania',
          privacyPolicyUrl: 'https://zadajdopyt.sk/http://localhost:3000/support/ochrana-sukromia',
        })
        await signInWithPopup(auth, provider)
          .then(async (result) => {
            if (result.user && result.user.email) {
              setLoading?.(true)
              await signUp({
                variables: {
                  input: {
                    authProvider: AuthProvider.Google,
                    firebaseUid: result.user.uid,
                    userType,
                    email: result.user.email,
                    firstName: firstName ?? result.user.displayName?.split(' ')[0] ?? '',
                    lastName: lastName ?? result.user.displayName?.split(' ')[1] ?? '',
                    phoneNumber,
                    subscriptionTier,
                  },
                },
              })
            }
          })
          .catch((error) => {
            setLoading?.(false)
            console.log('error', error)
            return false
          })
          .finally(() => {
            setIsOAuthSignUp(false)
            setLoading?.(false)
            if (shouldRedirect) {
              void router.push(Route.Dashboard())
            }
          })
        return true
      } catch (error) {
        Sentry.captureException(error)
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [router, signUp],
  )

  const signInWithEmailAndPassword = useCallback(
    async ({ email, password, shouldRedirect = true }: EmailAndPasswordInput) => {
      try {
        setIsLoading(true)
        await firebaseSignInWithEmailAndPassword(FirebaseAuth, email, password).then(() => {
          if (shouldRedirect) {
            void router.push(Route.App.DemandList())
          }
        })

        return true
      } catch (error) {
        const err = error as AuthError
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorToast({
              description: 'Neplatný email alebo heslo',
            })
            break
          case 'auth/network-request-failed':
            errorToast({
              description: 'Vyskytla sa chyba s intenetovým pripojením. Prosím skúste znova.',
            })
            break
          default:
            Sentry.captureException(err)
            errorToast({
              description: 'Neplatný email alebo heslo',
            })
        }

        return false
      } finally {
        setIsLoading(false)
      }
    },
    [errorToast, router],
  )

  const signOut = useCallback(
    async (shouldRedirect = true) => {
      try {
        setIsLoading(true)
        await FirebaseAuth.signOut()
        if (shouldRedirect) {
          void router.push(Route.FrontPage())
        }
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        void router.push(Route.FrontPage())
        setIsLoading(false)
      }
    },
    [router],
  )

  const changePassword = useCallback(
    (newPassword: string) =>
      FirebaseAuth.currentUser && updatePassword(FirebaseAuth.currentUser, newPassword),
    [],
  )

  const refetchAndSetUserData = useCallback(async () => {
    try {
      const response = await apolloClient.query<UserMeQuery>({
        query: UserMeDocument,
        fetchPolicy: 'network-only',
      })
      if (response.errors && response.errors.length > 0) {
        response.errors.forEach((error) => {
          Sentry.withScope((scope) => {
            scope.setContext('Additional Data', { scope })
            Sentry.captureException(new Error(error.message))
          })
          errorToast({ description: error.message })
        })
      }
      if (response.data.userMe) {
        Sentry.setUser({ email: response.data.userMe.email })
        setUserData(response.data.userMe)
      } else {
        errorToast({
          description: GENERAL_PERSISTS_ERROR,
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      await signOut()
    }
  }, [apolloClient, errorToast, signOut])

  const sendResetPasswordEmail = useCallback(
    async ({ email }: { email: string }) => {
      try {
        setIsLoading(true)
        await firebaseSendPasswordResetEmail(FirebaseAuth, email)
        setIsLoading(false)
        return true
      } catch (error) {
        const err = error as AuthError
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/user-not-found':
            errorToast({
              title: 'Forgot password',
              description: 'Email address is incorrect. We could not find the user.',
            })
            break
          case 'auth/network-request-failed':
            errorToast({
              title: 'Forgot password',
              description: 'Something went wrong with your internet connection. Please try again.',
            })
            break
          default:
            Sentry.captureException(err)
            errorToast({
              description: GENERAL_PERSISTS_ERROR,
            })
        }
        setIsLoading(false)
        return false
      }
    },
    [errorToast],
  )

  // TODO change redirect URL in Firebase console from localhost:3000 to real one
  const confirmPasswordReset = useCallback(
    async ({ resetCode, password }: ConfirmPasswordResetInput) => {
      try {
        setIsLoading(true)
        await firebaseConfirmPasswordReset(FirebaseAuth, resetCode, password)
        setIsLoading(false)
        return true
      } catch (error) {
        Sentry.captureException(error)
        setIsLoading(false)
        return false
      }
    },
    [],
  )

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isOAuthSignUp) {
      const subscribe = FirebaseAuth.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          void (async () => {
            await refetchAndSetUserData()
            setIsFirebaseInitializing(false)
          })()
        } else {
          setUserData(null)
          setIsFirebaseInitializing(false)
        }
      })
      return subscribe
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOAuthSignUp])

  const contextValue = useMemo(
    () => ({
      userData,
      isLoading,
      isFirebaseInitializing,
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      signInWithFirebaseToken,
      signOut,
      refetchAndSetUserData,
      sendResetPasswordEmail,
      confirmPasswordReset,
      isCustomer,
      isContractor,
      hasMissingOrExpiredSubscription,
      isValidContractor,
      hasMissingProfileInformations,
      changePassword,
      setIsOAuthSignUp,
      createUserWithGoogleOAuth,
    }),
    [
      userData,
      isLoading,
      isFirebaseInitializing,
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      signInWithFirebaseToken,
      signOut,
      refetchAndSetUserData,
      sendResetPasswordEmail,
      confirmPasswordReset,
      isCustomer,
      isContractor,
      hasMissingProfileInformations,
      hasMissingOrExpiredSubscription,
      isValidContractor,
      changePassword,
      setIsOAuthSignUp,
      createUserWithGoogleOAuth,
    ],
  )

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
export default UserProvider
