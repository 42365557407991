import { withSearchParams } from 'api/utils/common'
import { DemandStatus } from 'generated/generated-graphql'

enum SignUpSteps {
  UserSelection = 'typ-pouzivatela',
  PlanSelection = 'vyber-planu',
  AccountCreation = 'vytvorenie-uctu',
  UserDetails = 'kontaktne-informacie',
}

export type ReturnToSearchParam = {
  returnTo?: string
}

type FirebaseSignInSearchParam = {
  firebaseToken: string
}

export const Route = {
  FrontPage: (searchParams?: {
    openSignInModal?: boolean
    openPasswordRecoveryModal?: boolean
    openContactUsModal?: boolean
    recoveryKey?: string
    email?: string
    returnTo?: string
  }) => `/${withSearchParams(searchParams)}`,
  App: {
    Support: () => '/app/podpora',
    DemandList: (searchParams?: { listingTypeId?: number }) =>
      `/app/prehlad-dopytov/${withSearchParams(searchParams)}`,
    EditDirectOffer: ({ id }: { id: number }) => `/app/priama-ponuka/${id}/uprava-priamej-ponuky`,
    DirectOfferDetail: ({ id }: { id: number }) => `/app/priama-ponuka/${id}`,
    NewDirectOffers: () => `/dashboard/nova-priama-ponuka`,
    DirectOffers: () => `/app/priame-ponuky`,
    MyDirectOfferOrders: () => '/app/priame-ponuky/moje-objednavky',
    Demand: {
      CreateDemand: (searchParams?: { listingTypeId?: number; categoryId?: number }) =>
        `/app/novy-dopyt/${withSearchParams(searchParams)}`,
      EditDemand: ({ id }: { id: number }) => `/app/detail-dopytu/${id}/uprava-dopytu`,
      DemandDetail: ({ id }: { id: number }) => `/app/detail-dopytu/${id}`,
      CreateOffer: ({ demandId }: { demandId: number }) =>
        `/app/detail-dopytu/${demandId}/vytvorenie-ponuky`,
    },
  },
  Public: {
    Faq: () => '#faq',
    DemandList: (searchParams?: { listingTypeId?: number }) =>
      `/dopyty/${withSearchParams(searchParams)}`,
    DirectOffers: () => `/priama-ponuka`,
    ContractorLanding: () => `/dodavatelia`,
    DirectOfferDetail: ({ id }: { id: number }) => `/priama-ponuka/${id}`,
    CreateDemand: (searchParams?: { listingTypeId?: number; categoryId?: number }) =>
      `/novy-dopyt/${withSearchParams(searchParams)}`,
    PrivacyPolicy: () => '/support/ochrana-sukromia',
    TermsAndConditions: () => '/support/podmienky-pouzivania',
  },
  FirebaseSignIn: (searchParam?: FirebaseSignInSearchParam) =>
    `/firebase-sign-in${withSearchParams(searchParam)}`,
  Blog: (searchParams?: { currentCatergory?: string }) =>
    `/rady-typy${withSearchParams(searchParams)}`,
  ContractorOnboarding: () => '/onboarding',
  Messages: () => '/spravy',
  Dashboard: (searchParams?: { activeStatus?: DemandStatus }) =>
    `/moje-dopyty/${withSearchParams(searchParams)}`,
  OfferDetail: ({ id }: { id: number }) => `/detail-ponuky/${id}`,
  EditOffer: ({ id, demandId }: { id: number; demandId: number }) =>
    `/detail-ponuky/${id}/uprava-ponuky?demandId=${demandId}`,
  Profile: () => `/profil`,
  SignUp: (searchParams?: { krok?: SignUpSteps }) =>
    `/registracia/${withSearchParams(searchParams)}`,
  ContractorDetailPage: ({ id }: { id: number }) => `/zoznam-dodavatelov/${id}`,
  ProfilePages: {
    PersonalData: () => '/profil/osobne-udaje',
    CompanyProfile: () => '/profil/firemny-profil',
    PasswordManagement: () => '/profil/sprava-hesla',
    OfferTemplates: () => '/profil/sablony-ponuk',
    SubscriptionDetails: () => '/profil/predplatne',
    Settings: () => '/profil/nastavenia',
  },
  ContractorDashboard: {
    MyOffers: () => '/dashboard',
    MyDemands: (searchParams?: { activeStatus?: DemandStatus }) =>
      `/dashboard/dopyty/${withSearchParams(searchParams)}`,
    OfferTemplates: () => '/dashboard/cenove-ponuky',
    DirectOffers: () => '/dashboard/priame-ponuky',
  },
  SignIn: (searchParam?: ReturnToSearchParam) => `/prihlasenie${withSearchParams(searchParam)}`,
  ForgotPassword: () => `/forgot-password`,
  Error: '/error',
} as const
